import styled, { css } from "styled-components";
import colors from "../styles/constants/colors";
import Instagram from "../assets/icon/instagram.png";
import Youtube from "../assets/icon/youtube.png";
import Facebook from "../assets/icon/facebook.png";
import { useLocation } from "react-router-dom";
import flexbox from "../styles/function/flexbox";
import { useEffect } from "react";
import { useState } from "react";

const Footer = () => {
  function getOS() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;

    if (macosPlatforms.test(userAgent)) {
      os = "macos";
      setMacOs(true);
    } else if (iosPlatforms.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "windows";
    } else if (/android/.test(userAgent)) {
      os = "android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "linux";
    }

    return os;
  }
  const [macOs, setMacOs] = useState(false);

  const Locale = window.localStorage.getItem("locale");

  let Lang = "";

  if(Locale) {
    if(Locale == 'KOR') {
      Lang = true;
    } else {
      Lang = false;
    }
  }

  useEffect(() => {
    getOS();
  });

  const { pathname } = useLocation();

  return (
    <SFooter pathname={pathname}>
      <FooterWrap>
        <TopWrap>
          <MobileTop>
            <Strong>Service</Strong>
            <P>
              <a
                href="https://play.google.com/store/apps/details?id=kr.semos.partners"
                target="_blank"
                aria-label={Lang ? 'Semos Partner - Google Play' : "세모스 파트너 - 구글플레이"}
                rel="noreferrer"
              >
                {Lang ? 'Semos Partner - Google Play' : "세모스 파트너 - 구글플레이"} 
              </a>
            </P>
            <P>
              <a
                href="https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%ED%8C%8C%ED%8A%B8%EB%84%88/id1610055663"
                target="_blank"
                aria-label={Lang ? "Semos Partner - App Store" : "세모스 파트너 - 앱스토어"}
                rel="noreferrer"
              >
                {Lang ? 'Semos Partner - App Store' : "세모스 파트너 - 앱스토어"}
              </a>
            </P>
            <P>
              <a
                href="https://play.google.com/store/apps/details?id=com.report.semos"
                target="_blank"
                aria-label={Lang ? 'Semos - Google Play' : "세모스 - 구글플레이"}
                rel="noreferrer"
              >
                {Lang ? 'Semos - Google Play' : "세모스 - 구글플레이"} 
              </a>
            </P>
            <P>
              <a
                href="https://apps.apple.com/kr/app/세모스/id1578637889"
                target="_blank"
                aria-label={Lang ? "Semos - App Store" : "세모스 - 앱스토어"}
                rel="noreferrer"
              >
                {Lang ? 'Semos - App Store' : "세모스 - 앱스토어"}
              </a>
            </P>
          </MobileTop>
          <MobileTop>
            <Strong>Recruting</Strong>
            <P>
              <a
                href="https://semos.notion.site/8442c9b83f3d40d1995303aba1cea817"
                target="_blank"
                aria-label={ Lang ? "Reports job openings" : '리포츠 채용'}
                rel="noreferrer"
              >
                { Lang ? "Reports job openings" : '리포츠 채용'}
              </a>
            </P>
            <P>
              <a
                href="https://partner.semos.kr"/*"https://forms.gle/mpMXYxmcxyXetueB8"*/
                target="_blank"
                aria-label={ Lang ? "Join Now" : "파트너 등록하기"/*'등록 상담 신청하기'*/}
                rel="noreferrer"
              >
                { Lang ? "Join Now" : "파트너 등록하기"/*'등록 상담 신청하기'*/}
              </a>
            </P>
          </MobileTop>

          <MobileTop>
            <Strong>Contact Us</Strong>
            <P>help@semos.kr</P>
            <P>
              <span>{ Lang ? "KakaoTalk channel " : '카카오톡 채널 ' }</span>
              <a
                href="http://pf.kakao.com/_YxfVxfK"
                target="_blank"
                aria-label={ Lang ? `KakaoTalk channel ` : "카카오톡 채널" }
                rel="noreferrer"
              >
                @세상의 모든 스포츠, 세모스
              </a>
            </P>
          </MobileTop>

          <Center>
            <Strong style={{ color: colors.gray03 }}>{ Lang ? "Reports, Inc." : '리포츠(주)'}</Strong>

            <InfoWrap>
              <DivBox macOs={macOs}>
                <DList>
                  <DTerm>{ Lang ? "CEO :" : '대표 :'} </DTerm>
                  <dd>{ Lang ? "Minji Byun" : '변민지'}</dd>
                  <Bar />
                  <DTerm>{ Lang ? 'DPO :' : '개인정보책임자 :'} </DTerm>
                  <dd>{ Lang ? "Jaehyeok Huh" : '허재혁'}</dd>
                  <Bar mobile={true} />
                </DList>
              </DivBox>
              <Bar pc={true} />
              <DivBox>
                <DList>
                  <DTerm>{Lang ? 'Business Registration Number :' : '사업자등록번호 :'} </DTerm>
                  <dd>206-88-01662</dd>
                </DList>
              </DivBox>
            </InfoWrap>

            <Wrap>
              <DivBox macOs={macOs}>
                <Address>
                  <P mobile={true}>{Lang ? "Head Office: B1041-5,6, 119 Songdo Culture Road, Yeonsu-gu, Incheon, South Korea" : '본사 : 인천 연수구 송도문화로 119, B1041-5,6'}</P>
                  <Bar mobile={true} />
                </Address>
              </DivBox>
              <Bar pc={true} />
              <DivBox>
                <Address>
                  <P mobile={true}>{Lang ? 'Branch Office: 501-502 Teheran Road, Gangnam-gu, Seoul, South Korea' : '지사 : 서울 강남구 테헤란로 501 502호'}</P>
                </Address>
              </DivBox>
            </Wrap>
          </Center>
        </TopWrap>
        <BottomWrap>
          <InfoWrap>
            <P>Copyright © {Lang ? 'Reports, Inc.' : '리포츠 (주).'}  All rights reserved.</P>

            <P>
              {
                Lang ?
                  'Reports acts as an intermediary in online commerce and is not a party to the sales transaction. The obligations and responsibilities related to product reservations, usage, and refunds, among other things, lie with the respective sellers.'
                : 
                  "리포츠는 통신판매 중개자로서 통신판매의 당사자가 아니며 상품의 예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다."
              }
            </P>
          </InfoWrap>
          <IconWrap>
            <a
              href="https://www.facebook.com/semossports/"
              target="_blank"
              aria-label={Lang ? 'Semos Facebook' : "세모스 페이스북"}
              rel="noreferrer"
            >
              <Icon src={Facebook} />
            </a>

            <a
              href="https://www.instagram.com/semos_sports/"
              target="_blank"
              aria-label={Lang ? 'Semos Instagram' : "세모스 인스타그램" }
              rel="noreferrer"
            >
              <Icon src={Instagram} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCJPnOxX_AxNCL11A2ar7liw"
              target="_blank"
              aria-label={Lang ? "Semos Youtube" : "세모스 유튜브"}
              rel="noreferrer"
            >
              <Icon src={Youtube} />
            </a>
          </IconWrap>
        </BottomWrap>
      </FooterWrap>
    </SFooter>
  );
};

export default Footer;

const SFooter = styled.footer`
  p {
    white-space: pre-line;
  }
  background-color: ${colors.white};
  border-top: 2px solid #f5f5f5;
  @media screen and (min-width: 1024px) {
    padding: 57px 18vw 102px 18vw;
  }
  @media screen and (max-width: 1024px) {
    padding: 57px 18vw;
    height: auto;
    padding: 10vw;
  }
  @media screen and (max-width: 560px) {
    padding: 43px 20px 72px 20px;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    dd,
    dt,
    p {
      font-size: 11px;
    }
  }
`;

const Wrap = styled.div`
  margin-top: 8px;
  ${flexbox("flex-start", "flex-start", "row")}
  @media screen and (max-width: 1024px) {
    ${flexbox("flex-start", "flex-start", "column")}
    margin-top: 0px;
  }
`;

const TopWrap = styled.div`
  flex-wrap: wrap;

  ${flexbox("space-between", "space-between", "row")}
  @media screen and (max-width: 1024px) {
    gap: 20px;
  }
  @media screen and (max-width: 560px) {
    ${flexbox("flex-start", "flex-start", "column")}
  }
`;
const InfoWrap = styled.div`
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
    padding-bottom: 8px;
    white-space: pre-line;
  }

  ${flexbox("flex-start", "center", "row")} /* margin-bottom: 8px; */
@media screen and (min-width: 561px) and (max-width: 1024px) {
    gap: 8px;
    margin-bottom: 8px;
    ${flexbox("flex-start", "flex-start", "column")}
  }
  @media screen and (max-width: 560px) {
    ${flexbox("flex-start", "flex-start", "column")}
  }
`;

const Bar = styled.div`
  width: 1px;
  height: 16px;
  background-color: #d9d9d9;
  margin: 0 14px;

  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
    `}

  ${({ pc }) =>
    pc &&
    css`
      display: block;
    `}

  @media screen and (max-width: 1024px) {
    ${({ mobile }) =>
      mobile &&
      css`
        display: block;
      `}

    ${({ pc }) =>
      pc &&
      css`
        display: none;
      `}
  }
`;

const Strong = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.gray05};
  margin-bottom: 24px;
  @media screen and (max-width: 560px) {
    margin-bottom: 20px;
  }
`;

const P = styled.p`
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  white-space: pre;
  margin-bottom: 8px;
  @media screen and (max-width: 560px) {
    margin-bottom: ${({ mobile }) => (mobile ? "0px" : "8px")};
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 142%;
    letter-spacing: -0.02em;
    color: ${colors.gray03};
  }
`;
const DList = styled.dl`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.gray03};
`;

const DTerm = styled.dt`
  white-space: pre;
`;

const Address = styled.address`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.gray03};
  white-space: pre;
  p {
    @media screen and (min-width: 560px) {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 1024px) {
    ${flexbox("flex-start", "center", "row")}
  }
`;

const DivBox = styled.div`
  /* width: 260px; */
  width: ${({ macOs }) => (macOs ? "300px" : "360px")};
  @media screen and (max-width: 1024px) {
    width: 300px;
    address {
      margin-bottom: 8px;
    }
  }
  @media screen and (max-width: 560px) {
    margin-bottom: 8px;
    width: 270px;
    address {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: 320px) {
    width: auto;
  }
`;

const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media screen and (min-width: 1024px) {
    gap: 58px;
  }
  @media screen and (max-width: 560px) {
    gap: 18px;
  }
`;

const BottomWrap = styled.div`
  p {
    font-weight: 500;
    line-height: 142%;
    letter-spacing: -0.02em;
  }
  p:first-child {
    font-size: 14px;
    color: ${colors.blue06};
    margin-right: 47px;
  }
  p:last-child {
    font-size: 12px;
    color: ${colors.gray03};
    white-space: pre-line;
  }
  ${flexbox("space-between", "center", "row")}
  @media screen and (max-width: 1024px) {
    gap: 33px;
    ${flexbox("flex-start", "flex-start", "column-reverse")}
  }

  @media screen and (max-width: 560px) {
    gap: 66px;
  }
`;

const Icon = styled.img`
  width: 28px;
  margin-left: 22px;
  @media screen and (max-width: 1024px) {
    margin-right: 22px;
    margin-left: 0px;
  }
`;
const Center = styled.div`
  width : 100%;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 560px) {
    margin-top: 28px;
  }
`;
const MobileTop = styled.div`
  @media screen and (min-width: 1024px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 1024px) {
    /* margin-top: 32px; */
    width: 300px;
  }

  @media screen and (max-width: 560px) {
    /* margin-top: 32px; */
    width: auto;
  }
`;

const IconWrap = styled.div`
  ${flexbox("center", "center", "row")}
`;
