import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import semosLogoW from "../assets/images/logo/reportsLogo.png";
import semosLogoB from "../assets/images/logo/reportsLogo-b.png";
const Wrap = styled.div`
  width: 137px;
  height: 33px;
  @media screen and (max-width: 560px) {
    height: 13.5px;
    width: 53px;
  }
`;

const SLink = styled(Link)`
  display: block;
`;

const Img = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 560px) {
    ${({ position }) =>
      position > 60 &&
      css`
        background-image: url(${semosLogoB});
      `}

    ${({ position }) =>
      position < 60 &&
      css`
        background-image: url(${semosLogoW});
      `}
  }
  ${({ position }) =>
    position > 90 &&
    css`
      background-image: url(${semosLogoB});
    `}

  ${({ position }) =>
    position < 90 &&
    css`
      background-image: url(${semosLogoW});
    `}
  background-size: contain;
  background-repeat: no-repeat;
`;

const Logo = ({ position }) => {
  const { pathname } = useLocation();

  return (
    <SLink
      to="/"
      aria-label="홈으로 가기"
      onClick={() => {
        if ("/" !== pathname) {
          window.scrollTo(0, 0);
        }
      }}
    >
      <Wrap>
        <Img position={position} />
      </Wrap>
    </SLink>
  );
};

export default Logo;
