import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "../styles/constants/colors";
import opacity from "../styles/constants/opacity";
import responsive from "../styles/constants/responsive";
import flexbox from "../styles/function/flexbox";
import transitionStyle from "../styles/function/transition";

const UList = styled.ul`
  display: flex;
  color: ${colors.blue07};
  margin-left: 0;
  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 142%;
    letter-spacing: -0.02em;
    color: ${({ position }) => (position > 90 ? colors.black : colors.white)};

    @media screen and (max-width: 560px) {
      font-size: 12px;
      color: ${({ position }) => (position > 60 ? colors.black : colors.white)};
      width: 100%;
    }
  }
  @media screen and (max-width: 560px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    /* display: flex; */
    ${flexbox("space-around", "center", "row")}
  }
  @media screen and (max-width: 320px) {
    gap: 3px;
  }
`;

const Item = styled.li`
  /* width: 100%; */
  margin: 10px 24px;
  @media screen and (min-width: 561px) and (max-width: 1024px) {
    margin: 10px;
  }
  @media screen and (max-width: 560px) {
    margin: 10px 0;
  }
  white-space: nowrap;
  :first-child {
    margin-left: 69px;
    @media screen and (min-width: 561px) and (max-width: 1024px) {
      margin-left: 20px;
    }
    @media screen and (max-width: 560px) {
      margin: 0;
    }
  }

  a {
    margin: 0;
    padding: 0;
    transition: ${transitionStyle("opacity")};
    position: relative;
    font-weight: ${({ pathname, thisLink }) =>
      pathname === thisLink ? "600" : "500"};
    ${({ pathname, thisLink }) =>
      pathname === thisLink &&
      css`
        color: ${({ position }) =>
          position > 90 ? colors.blue06 : colors.blue03};
        @media screen and (max-width: 560px) {
          color: ${({ position }) =>
            position > 60 ? colors.blue06 : colors.blue03};
        }
        ::after {
          position: absolute;
          content: "";
          width: 4px;
          height: 4px;
          background-color: ${({ position }) =>
            position > 90 ? colors.blue06 : colors.blue03};

          @media screen and (max-width: 560px) {
            background-color: ${({ position }) =>
              position > 60 ? colors.blue06 : colors.blue03};
          }
          border-radius: 2px;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: -12px;
        }
      `}

    &:hover,
    &:active {
      opacity: ${opacity.base};
    }
  }
`;

const SLink = styled(Link)`
  /* padding: 10px; */

  ${responsive.mediaQuery.tablet} {
    color: ${({ pathname }) =>
      pathname === "/" ? colors.white : colors.blue07};
  }
`;

const NavList = ({ kind = "nav", position, lang }) => {
  const { pathname } = useLocation();

  return (
    <UList position={position} pathname={pathname} kind={kind}>
      <Item position={position} pathname={pathname} thisLink={"/"}>
        <SLink
          to="/"
          onClick={() => {
            if ("/" !== pathname) {
              window.scrollTo(0, 0);
            }
          }}
        >
          {lang ? 'Home' : '홈'}
        </SLink>
      </Item>

      <Item position={position} pathname={pathname} thisLink={"/reports_intro"}>
        <SLink
          to="/reports_intro"
          onClick={() => {
            if ("/reports_intro" !== pathname) {
              window.scrollTo(0, 0);
            }
          }}
        >
          {lang ? 'Company' : '회사소개'}
        </SLink>
      </Item>

      <Item
        position={position}
        pathname={pathname}
        thisLink={"/reports_recruit"}
      >
        <SLink
          to="/reports_recruit"
          onClick={() => {
            if ("/reports_recruit" !== pathname) {
              window.scrollTo(0, 0);
            }
          }}
        >
        {lang ? 'Recruting' : '채용'}
        </SLink>
      </Item>

      {/*!lang ? <Item pathname={pathname} thisLink={"/none"}>
        <a
          href="https://semos.notion.site/efbf8795207341788e54c4ebe182586a"
          target="_blank"
        >
        {lang ? 'Partner' : '서비스 소개서'}
        </a>
      </Item> : "" */}
    </UList>
  );
};

export default NavList;
