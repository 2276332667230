const colors = {
  blue: "#0E6DFF",
  skyBlue: "#CCE0FF",
  violet: "#6D37BE",
  darkGray: "#616161",
  vanilla: "#F4F4F4",

  white: "#fff",
  black: "#3C3E41",
  blue01: "#F2F7FF",
  blue02: "#E5F0FF",
  blue03: "#CCE0FF",
  blue04: "#80B2FF",
  blue05: "#66A2FF",
  blue06: "#3383FF",
  blue07: "#0E6DFF",
  blue08: "#003C99",
  gray01: "#F9F9F9",
  gray02: "#DCDCDC",
  gray03: "#ACAFB5",
  gray04: "#818792",
  gray05: "#3C3E41",
  gray06: "#1A1C1F",
  gray07: "#000000", //black
  red: "#FF6052",
  green: "#96F24E",
  yellow: "#FFD966",
};

export default colors;
