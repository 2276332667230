import styled from "styled-components";
import { useLocation } from "react-router-dom";
import flexbox from "../styles/function/flexbox";
import Logo from "../components/logo";
import NavList from "../components/navList";
import NavListRight from "../components/navListRight";
import zIndex from "../styles/constants/z-index";
import { useEffect, useRef, useState } from "react";
import colors from "../styles/constants/colors";

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ position }) =>
    position > 90 ? colors.white : "transparent"};
  z-index: ${zIndex.navbar};
  padding: 0 144px;
  color: black;

  @media screen and (min-width: 561px) and (max-width: 1024px) {
    padding: 0 15px;
  }
  @media screen and (max-width: 560px) {
    background-color: ${({ position }) =>
      position > 60 ? colors.white : "transparent"};
    padding: 0px;
  }
`;

const NavSubWrapper = styled.div`
  ${flexbox("space-start")}
  @media screen and (max-width: 560px) {
    ${flexbox("space-between")}
    width: 100%;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

const NavWrapper = styled.div`
  width: 100%;
  height: 90px;
  ${flexbox("space-between")}
  button {
    transform: translateX(10px);
  }
  @media screen and (max-width: 560px) {
    height: 60px;
    padding: 0 19px;
    justify-content: flex-start;
    /* gap: 14px; */
  }

  @media screen and (max-width: 320px) {
    padding: 0 5px;
  }
`;

const Gnb = () => {
  const pointRef = useRef(null);
  const { pathname } = useLocation();

  const [position, setPosition] = useState(0);

  const Locale = window.localStorage.getItem("locale");

  let Lang = "";

  if(Locale) {
    if(Locale == 'KOR') {
      Lang = true;
    } else {
      Lang = false;
    }
  }

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => { 
    let Lang = window.navigator.language;
  
    if(!Lang) {
      Lang = window.navigator.userLanguage;
    }
  
    Lang = Lang.substring(0,2);
  
    if(!Locale) {
      if(Lang == 'ko') {
        window.localStorage.setItem("locale", "ENG");
        window.location.reload();
      } else {
        window.localStorage.setItem("locale", "KOR");
        window.location.reload();
      }
    }
  }, []);

  return (
    <>
      <Nav position={position} ref={pointRef} pathname={pathname}>
        <NavWrapper>
          <NavSubWrapper>
            <Logo position={position} />
            <NavList position={position} lang={Lang} />
          </NavSubWrapper>
          <NavListRight position={position} lang={Lang} />
        </NavWrapper>
      </Nav>
    </>
  );
};

export default Gnb;
