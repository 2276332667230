import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

// components
import colors from "../styles/constants/colors";
import opacity from "../styles/constants/opacity";

// images
import transitionStyle from "../styles/function/transition";
import HeaderButton from "../assets/icon/HeaderButtonWhite.png";
import HeaderButtonB from "../assets/icon/HeaderButtonBlue.png";
import HeaderButtonEng from "../assets/icon/HeaderButtonWhiteEng.png";
import HeaderButtonBEng from "../assets/icon/HeaderButtonBlueEng.png";

const UList = styled.ul`
  display: flex;
  flex-direction: ${({ kind }) => (kind === "side" ? "column" : "")};
  gap: 15px;
  align-items: center;
`;
const ButtonWrap = styled.div`
  height: 36px;
  width: 124px;

  @media screen and (max-width: 560px) {
    height: 24px;
    width: 83px;
  }
`;
const ButtonImg = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 560px) {
    ${({ position }) =>
      position > 60 &&
      css`
        background-image: url(${HeaderButtonB});
      `}

    ${({ position }) =>
      position < 60 &&
      css`
        background-image: url(${HeaderButton});
      `}
  }
  ${({ position }) =>
    position > 90 &&
    css`
      background-image: url(${HeaderButtonB});
    `}

  ${({ position }) =>
    position < 90 &&
    css`
      background-image: url(${HeaderButton});
    `}
`;

const ChangeLocale = styled.div`
          height : 32px;
          border-radius : 10px;
          display : inline-flex;
          align-items : center;
          justify-content : center;
          background-color : #ffffff;
          border : 2px solid ${colors.blue07};
          font-size : 14;
          color: ${colors.blue07};
          font-weight : 700;
          padding-left : 10px;
          padding-right : 10px;
          margin-left : 10px;
          cursor : pointer;

          @media screen and (max-width: 560px) {
            height : 24px;
            font-size : 12px;
            margin-left : 0px;
            padding-left : 7px;
            padding-right : 7px;
            position : fixed;
            bottom : 10px;
            right : 10px;
          }
`;

const ButtonImgEng = styled.div`
width: 100%;
height: 100%;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
@media screen and (max-width: 560px) {
  ${({ position }) =>
    position > 60 &&
    css`
      background-image: url(${HeaderButtonBEng});
    `}

  ${({ position }) =>
    position < 60 &&
    css`
      background-image: url(${HeaderButtonEng});
    `}
}
${({ position }) =>
  position > 90 &&
  css`
    background-image: url(${HeaderButtonBEng});
  `}

${({ position }) =>
  position < 90 &&
  css`
    background-image: url(${HeaderButtonEng});
  `}
`;

const Item = styled.li`
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  width: 100%;
  margin: 10px 0;
  white-space: nowrap;

  a {
    border: 1.6px solid ${colors.skyBlue};
    color: ${colors.skyBlue};
    border: ${({ position }) =>
      position > 90
        ? `1.6px solid ${colors.blue06}`
        : `1.6px solid ${colors.blue03}`};
    color: ${({ position }) => (position > 90 ? colors.blue06 : colors.blue03)};
    padding: 8px 18px;
    border-radius: 20px;
    transition: ${transitionStyle("opacity")};
    font-weight: 700;

    &:hover,
    &:active {
      /* opacity: ${opacity.base}; */
    }
  }

  @media screen and (max-width: 1024px) {
    display: ${({ pc }) => (pc ? "none" : "block")};
  }
  @media screen and (min-width: 561px) and (max-width: 1024px) {
    a {
      padding: 8px 12px;
    }
    font-size: 14px;
  }
  @media screen and (max-width: 560px) {
    color: ${({ position }) => (position > 60 ? colors.blue06 : colors.blue03)};
    a {
      padding: 5px 8px;
    }
    font-size: 10px;
  }
`;
const RightWrap = styled.div`
  display: inline-flex;
  align-items: center;
`;

const NavList = ({ kind = "nav", position, lang }) => {
  const Locale = window.localStorage.getItem("locale");
  const { pathname } = useLocation();
  const [macOS, setMacOS] = useState(false);
  const [locale, setLocale] = useState(Locale ? Locale : "ENG");

  useEffect(() => {
    setMacOS(window.navigator.userAgent.includes("Macintosh"));
  });

  const SetLocaleEvent = () => {

    if(locale == "ENG") {
      window.localStorage.setItem("locale", "KOR");
      window.location.reload();
    } else {
      window.localStorage.setItem("locale", "ENG");
      window.location.reload();
    }

  }

  const iOSCheck = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  return (
    <UList pathname={pathname} kind={kind}>
      {/*<Item
        pc={true}
        position={position}
        pathname={pathname}
        thisLink={"/none"}
      >
        <a
          href="https://semos.notion.site/efbf8795207341788e54c4ebe182586a"
          target="_blank"
          aria-label={lang ? "Partner collaboration" : "파트너 제휴"}
        >
          {lang ? "Partner collaboration" : '파트너 제휴'}
        </a>
      </Item>*/}
      <RightWrap position={position} pathname={pathname} thisLink={"/none"}>
        <a
          href={ "https://partner.semos.kr"/*'https://forms.gle/mpMXYxmcxyXetueB8'*/
            /*iOSCheck || macOS
              ? "https://apps.apple.com/kr/app/%EC%84%B8%EB%AA%A8%EC%8A%A4-%EC%84%B8%EC%83%81%EC%9D%98-%EB%AA%A8%EB%93%A0-%EC%8A%A4%ED%8F%AC%EC%B8%A0-%EA%B0%95%EC%8A%B5%EC%9D%B4-%EB%82%B4-%EC%86%90-%EC%95%88%EC%97%90/id1578637889"
              : "https://play.google.com/store/apps/details?id=com.report.semos"*/
          }
          rel="noreferrer"
          target="_blank"
          aria-label={lang ? 'JOIN NOW' : "파트너 등록하기"/*"등록 상담 신청하기"*/}
        >
          <ButtonWrap>
            {lang
              ?
                <ButtonImgEng position={position} />
              :
                <ButtonImg position={position} />
            }
            
          </ButtonWrap>
        </a>
        <ChangeLocale onClick={() => SetLocaleEvent()}>
          {locale}
        </ChangeLocale>
      </RightWrap>
    </UList>
  );
};

export default NavList;
